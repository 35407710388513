import React, { useEffect, useState, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Checkout from './Checkout';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { CircularProgress } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';
// import logo from "../assets/regal_images/pro_fav.png"

export default function StripePayment() {
    const temp = useRef()
    const location = useLocation()
    const user_details = JSON.parse(sessionStorage.getItem('user'))
    const [clientSecret, setClientSecret] = useState("");
    const [userData, setUserData] = useState(location?.state?.paymentData)

    console.log("userData",userData)

    const stripePromise = loadStripe(userData?.payment_key);
    // const stripePromise = loadStripe('pk_test_51Kys5jFKI1bFBUbsOXpEQulwBFDErQLUzYZf8TS5IT7v2TEdRzCsp2idE5FKjf2Z6E0U5Rc6Ku6b9tidqs5yY1Mp00lsAAgYGO');

    const stripe = require('stripe')(userData?.secret_key);
    // const stripe = require('stripe')('sk_test_51Kys5jFKI1bFBUbs8aujxc3QE1znPkx4TuHKL4wxrpJLqppPQfxcBiAZkq8Om2KaKxKUmKL0gAdBWkoWYelDUAWL00fZeSTqoj');


    useEffect(() => {
        (async () => {
            const customer = await stripe.customers.create({
                email: userData?.email,
                name: userData?.name,
                address: {
                    line1: 'Westgate Business Bay',
                    city: 'Ahmedabad',
                    state: 'Gujarat',
                    postal_code: '0872',
                    country: 'AU',
                },
                shipping: {
                    address: {
                        line1: 'Westgate Business Bay',
                        city: 'Ahmedabad',
                        state: 'Gujarat',
                        postal_code: '0872',
                        country: 'AU',
                    },
                    name: 'Harshil Patel', // Include the name for shipping
                    phone: '+61285038000'

                },
              
            });
            console.log("customer", customer)
            const paymentIntent = await stripe.paymentIntents.create({
                amount: (userData.amount) * 100,
                currency: userData?.currency || 'usd',
                customer: customer.id,
                description: "Gujju Stream Payment",
                metadata: {
                    adfree: userData?.adfree || "false",
                    category_type: userData?.category_type || null,
                    email: userData?.email,
                    slug: userData?.slug || null,
                    mobile_no: userData?.mobile,
                    movie: userData?.movie || null,
                    name: userData?.name,
                    plan: userData?.plan,
                    promocode: userData?.promocode || null,
                    series: userData?.series || null,
                    transaction_type: userData?.transaction_type || null
                },
                automatic_payment_methods: {
                    enabled: true,
                },
            });
            setClientSecret(paymentIntent?.client_secret)

        })();
    }, [])
    const appearance = {
        theme: 'night',
        labels: "floating",

        variables: {
            colorPrimary: '#ffffff',
            colorBackground: '#000000',
            colorText: '#ffffff',
            colorDanger: '#df1b41',
            fontFamily: 'verdana',
            spacingUnit: '4px',
            borderRadius: '1rem',

        },

        rules: {
            '.Input, .Block': {
                // backgroundColor: 'transparent',
                border: '1.5px solid grey'
            }
        }
    };

    const options = {
        clientSecret: clientSecret,
        appearance

    };
    useMemo(() => {
        console.log("style")

    }, [options.clientSecret])
    console.log(userData, user_details)
    return (
        <div >
            <Container style={{ marginTop: "6rem" }}>
                <Row style={{ alignItems: "center" }}>
                    <Col lg="4" className="" style={{ border: "1px solid grey", padding: "30px", borderRadius: "1rem", width: "95%", margin: "auto" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                {/* <img src={logo} alt='logo' style={{ width: "200px" }} /> */}
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h5>Transaction Details</h5>


                                </div>
                                <hr />
                                <div className='pt-1'>
                                    <p> Name : {userData?.name} <br /></p>
                                    <p>  Mobile : {userData?.mobile}<br /></p>
                                    <p> Email : {userData?.email}</p>
                                    <p style={{ color: "#eb5829" }}>Amount :  {Number(userData?.amount).toFixed(2)} {userData?.currency} </p>
                                </div>
                            </div>

                            {/* <div>
                                Amount : {userData?.amount + " " + userData?.currency?.toUpperCase()}
                            </div> */}
                        </div>

                    </Col>
                    <Col lg="8" className='mt-2' >
                        {clientSecret ? (
                            <div ref={temp} style={{ border: "1px solid grey", padding: "30px", borderRadius: "1rem" }}>
                                <div className='mb-3' style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h6>Go further with Card</h6>
                                    {/* <img src={logo} alt='logo' style={{ width: "40px" }} /> */}

                                </div>

                                <Elements stripe={stripePromise} options={options}>
                                    <Checkout data={userData} />
                                </Elements>
                            </div>
                        ) :
                            <div style={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center" }}>
                                <CircularProgress />
                            </div>}
                    </Col>
                </Row>
            </Container>
            {/* {clientSecret ? (
                <Container style={{ marginTop: "6rem" }}>
                    <div ref={temp} style={{ border: "1px solid grey", padding: "30px", borderRadius: "1rem" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <p> Name : {userData?.name} <br /></p>
                                <p>  Mobile : {userData?.mobile}<br /></p>
                                <p> Email : {userData?.email}</p>
                                <p>Amount : {userData?.amount + " " + userData?.currency?.toUpperCase()} </p>
                            </div>
                            <div>
                                <img src={logo} alt='logo' style={{ width: "200px" }} />
                            </div>
                            
                        </div>

                        <Elements stripe={stripePromise} options={options}>
                            <Checkout />
                        </Elements>
                    </div>
                </Container>
            ) :
                <div style={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center" }}>
                    <CircularProgress />
                </div>} */}
        </div>
    );
};

