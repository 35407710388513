import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Container, Button, Row, Col, Form, InputGroup } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import "./form.css"
// rtl 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { all_avatar_list, profile_edit } from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
import bg from "../../../../assets/images/signup_background.jpg"
import user from '../../../../assets/images/user/user.jpg'
import OtpInput from 'react-otp-input';

import FormControl from "@mui/material/FormControl";
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import { PROFILE_MESSAGE } from '../../../../Actiontype/ActionType';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
const ToggleSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#fff",
        '&:hover': {
            backgroundColor: alpha("#fff", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#fff",
    },
    '& .MuiSwitch-switchBase': {
        color: "#fff",
        '&:hover': {
            backgroundColor: alpha("#fff", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: "#fff",
    },
}));
const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const Profile_Edit = (props) => {
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const dispatch = useDispatch()
    const [form, setForm] = useState({ profileType: "main", interest: "", user: user_id || 211 })
    let history = useHistory()
    const location = useLocation()
    const [show, setShow] = useState(false);
    const pinRef = useRef()
    useEffect(() => {
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
    })
    const avatars = useSelector((state) => state.home.avatars);

    useEffect(() => { dispatch(all_avatar_list()) }, [])


    const [showAlert, setShowAlert] = useState(false);

    console.log("location", location)

    useMemo(() => {
        setForm({ ...form, ...location?.state?.profile })

    }, [location?.state?.profile])
    console.log(form, location?.state?.profile, "profile_edit")
    const message = useSelector((state) => state.home.profile_message);

    useMemo(() => {
        if (message?.statuscode == 200 && message?.message != undefined) {
            history.push("/profile_manage")
            dispatch({ type: PROFILE_MESSAGE, payload: null })
        }
    }, [message])
    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(profile_edit({ ...form, profile_secure: JSON.stringify(form?.profile_secure) }));
    }
    return (
        <>
            <div className={`rtl-box ${show === true ? 'show' : ''}`} >
                <button type="button" className="btn btn-light rtl-btn">
                    <svg onClick={() => setShow(!show)} xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 20 20" fill="white">
                        <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                    </svg>
                </button>
                <div className="rtl-panel">
                    <ul className="modes">
                        <li className="dir-btn" data-mode="rtl" data-active="false" onClick={() => { props.rtlModeAction('ltl') }} data-value="ltr"><Link to="#">LTR</Link></li>
                        <li className="dir-btn" data-mode="rtl" data-active="true" onClick={() => { props.rtlModeAction('rtl') }} data-value="rtl"><Link to="#">RTL</Link></li>
                    </ul>
                </div>
            </div>
            <section className="sign-in-page" style={{ marginTop: "4rem", marginBottom: "21.5rem" }}>
                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="7" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <Form onSubmit={(event) => handleSubmit(event)}>
                                            <p style={{ textAlign: "center", marginBottom: "1rem", fontWeight: "400", fontSize: "24px" }}>Edit Profile</p>
                                            <Row style={{ display: "flex", justifyContent: "center" }}>


                                                <Col md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                    <Form.Group>
                                                        {form?.profileImage != undefined && avatars ? <img src={form?.profileImage} width={"100px"} className="rounded img-fluid d-block mx-auto mb-3" /> : <p></p>}


                                                        <Form.Control type="text" disabled value={form?.profileName} className="mb-0" id="exampleInputEmail2" />
                                                        {/* <Form.Control.Feedback  type="invalid" style={{textAlign:"center"}}>
              Please enter the Profile name.
            </Form.Control.Feedback> */}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {/* <Row style={{ display: "flex", justifyContent: "center" }}>


                                                <Col md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                    <Form.Group>
                                                        <FormControlLabel control={<ToggleSwitch checked={form?.profile_secure}
                                                            onChange={(event) => (setForm({ ...form, profile_secure: event.target.checked, profile_pin: event.target.checked ? form?.profile_pin : "" }))} />} labelPlacement="start" label="Secure your profile with pin" />
                                                    </Form.Group>
                                                </Col>
                                            </Row> */}
                                            {form?.profile_secure && <Row style={{ display: "flex", justifyContent: "center" }}>


                                                <Col md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                    <Form.Group>
                                                        <InputGroup>
                                                            <FormControl ref={pinRef}>
                                                                <OtpInput

                                                                    containerStyle={{ display: "flex", justifyContent: "space-evenly" }}
                                                                    value={form?.profile_pin}
                                                                    onChange={(a) => (setForm({ ...form, profile_pin: a }))}
                                                                    numInputs={4}
                                                                    inputStyle={{ fontSize: "3em", marginBottom: "1rem", padding: "0", border: "none", borderRadius: "0", borderBottom: "2px solid white", background: "transparent" }}
                                                                    inputType='password'
                                                                    renderSeparator={<span> &nbsp;&nbsp; </span>}
                                                                    renderInput={(props) => <input {...props} />}
                                                                    id={"pin"}
                                                                />

                                                            </FormControl>
                                                            <Button variant="text" style={{ background: "transparent", position: "absolute", right: "-5rem" }} onClick={(e) => {
                                                                console.log(pinRef.current.children[0].children)
                                                                const temp = pinRef.current.children[0].children
                                                                for (let i = 0; i < temp.length; i += 2) {
                                                                    if (temp[i].type == "password") {
                                                                        temp[i].type = "text"
                                                                        document.getElementById("pin_icon").className = "fa fa-eye"
                                                                    }
                                                                    else {
                                                                        temp[i].type = "password"
                                                                        document.getElementById("pin_icon").className = "fa fa-eye-slash"

                                                                    }
                                                                }
                                                            }}  >
                                                                <i className="fa fa-eye-slash" id="pin_icon" style={{ color: "#ffffff" }}></i>
                                                            </Button></InputGroup>
                                                    </Form.Group>
                                                </Col>
                                            </Row>}
                                            <Row style={{ display: "flex", justifyContent: "center", overflowY: "hidden" }}>
                                                {avatars?.data?.map((ele) => <Col md="2" style={{ display: "flex", justifyContent: "center" }}>
                                                    <img src={IMAGE_ENDPOINT + ele?.avatarImage} onClick={() => (setForm({ ...form, profileImage: IMAGE_ENDPOINT + ele?.avatarImage, avatar: ele?.id }))} width={"100px"} className="rounded img-fluid d-block mx-auto mb-3" alt="user" />
                                                </Col>)}

                                            </Row>

                                            <Button type='submit' style={{ display: "block", margin: "0 auto" }} className="btn btn-hover" >Submit</Button>

                                        </Form>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile_Edit)