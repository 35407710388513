import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Nav, Tab, Card, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import * as MovieAction from '../../../actions/MovieAction'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

export default function SubOTT() {
    const dispatch = useDispatch()
    const { getAllSubOTT } = bindActionCreators(MovieAction, dispatch)
    const [ott, setOTT] = useState([])

    useEffect(() => {
        (async () => {
            const resData = await getAllSubOTT()
            console.log("ott", resData)
            setOTT(resData?.data)
        })();
    }, [])

    return (
        <div>
            <div className='main-content'>
                <section id="iq-favorites">
                    <Container fluid>
                        <Row>
                            <Col sm="12" className="overflow-hidden">
                                <div className="d-flex align-items-center justify-content-between">
                                    {/* <h6 className="main-title" style={{ textTransform: "uppercase" }}>Subott</h6> */}
                                </div>
                                <div id="favorites-contens">
                                    <Swiper
                                        navigation={{
                                            prevEl: '#prev',
                                            nextEl: '#next'
                                        }}
                                        breakpoints={{
                                            320: { slidesPerView: 2 },
                                            450: { slidesPerView: 3 },
                                            638: { slidesPerView: 4 },
                                            991: { slidesPerView: 5 },
                                            1300: { slidesPerView: 6 },
                                            1650: { slidesPerView: 7 },
                                            1900: { slidesPerView: 8 },
                                            2300: { slidesPerView: 9 }


                                        }}
                                        // loop={true}
                                        slidesPerView={6}
                                        spaceBetween={10}
                                        as="ul"
                                        autoplay={{
                                            delay: 3000,
                                        }}
                                        speed={500}
                                        className="genre-parent">
                                        {
                                            ott?.map((data, index) => (
                                                <SwiperSlide key={index} style={{ textAlign: "center" }} as="li">
                                                    <a href={data?.web_link} target='_blank'>
                                                        <div style={{ marginBottom: "10px" }} >
                                                            <img src={IMAGE_ENDPOINT + data?.image} className='language-img' alt={'cast'} />
                                                        </div>
                                                        <span style={{ color: "white", fontSize: "0.9rem" }}>
                                                            {data?.ott_name}
                                                        </span>
                                                    </a>
                                                </SwiperSlide>
                                            ))
                                        }



                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>


            </div>


        </div>
    )
}
